<template>
  <BaseModal
    dataClass="definition-item-modify-modal"
    size="lg"
    v-bind="$attrs"
    v-on="$listeners"
    @show-modal="clear(obj)"
  >
    <template v-slot:main>
      <div data-class="work-list" class="mx-2 my-2">
        <BaseSearchCondition
          :searchCondition="searchCondition"
          :searchConditionInfo="searchConditionInfo"
          @clear="clear(obj)"
          @search="search(obj)"
        >
        </BaseSearchCondition>
        <br />
        <!-- テーブル -->
        <BasePagingTable
          v-model="searchCondition.size"
          id="work-list-table"
          :selectMode="selectMode"
          :fields="fields"
          :items.sync="tableItems"
          :selected-items.sync="selectedItem"
          :columnInfoPage="pagingInfo.page"
          :columnInfoSize="pagingInfo.size"
          :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
          :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
          :perPage="perPage"
          @size-changed="updateSize"
        />
      </div>
    </template>
    <template v-slot:footer="{ cancel }">
      <!-- 選択ボタン -->
      <BaseButton
        variant="primary"
        icon="check-circle"
        :columnInfo="resultControlInfo.select"
        :disabled="!multiSelected"
        @click="select"
      />
      <!-- 選択解除ボタン -->
      <BaseButton
        v-if="useUnset"
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.unselect"
        @click="unselect"
      />
      <!-- 閉じるボタン -->
      <BaseButton
        variant="primary"
        icon="times-circle"
        :columnInfo="resultControlInfo.close"
        @click="cancel"
      />
      <!-- 部署マスタモーダル -->
      <DepartmentModal
        :id="makeSubModalId"
        type="entry"
        :displayModals="displayModals"
        :selectedSearchCondition="departmentModalSearchCondition"
        @after-close-set="afterCloseSetDepartment"
        @after-close-unset="afterCloseUnsetDepartment"
      />
    </template>
  </BaseModal>
</template>
<script>
import {
  getListOptions,
  getSearchMaxCount,
  getCountPerReferencePage,
  openSubModal,
} from '@/common/Common.js'
import { search, clear } from '@/master/vehicle/VehicleModal.js'
import { DELETED_KIND, DEPARTMENT_KIND } from '@/common/const.js'

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
  },
  data() {
    return {
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],

      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      perPage: getCountPerReferencePage(),
      obj: this,
      departmentModalSearchCondition: {},
    }
  },

  props: {
    selectMode: {
      type: String,
      default: 'range',
    },
    displayModals: {
      type: String,
      // 'one'  ->'vehicle-modal'
      // 'two'  ->'vehicle-modal-edit'
      // 'three'  ->'vehicle-modal-three'
      default: 'one',
    },
    useUnset: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.VEHICLE_MODAL_LIST
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        page: searchConditionInfo.page,
        size: searchConditionInfo.size,
        inputComponents: [
          {
            // 車両コード
            type: 'text',
            id: 'vehicleCode',
            columnInfo: searchConditionInfo.vehicle_code,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
          },
          {
            // 車両名
            type: 'text',
            id: 'vehicleName',
            columnInfo: searchConditionInfo.vehicle_name,
          },
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CARRIER
              //this.openSubModal(this, 'department-modal-edit')
              this.openSubModal(this, this.makeSubModalId)
            },
          },
          {
            // ナンバープレート
            type: 'text',
            id: 'numberplate',
            maxLength: 50,
            columnInfo: searchConditionInfo.numberplate,
          },
          {
            // 車型
            type: 'select',
            id: 'vehicleModel',
            columnInfo: searchConditionInfo.vehicle_model,
            options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
            onChangeCallback: () => {},
          },
          {
            // 輸送手段カテゴリ
            type: 'select',
            id: 'vehicleCategory',
            columnInfo: searchConditionInfo.vehicle_category,
            options: getListOptions(this.MASTER_CODE.VEHICLE_CATEGORY),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.VEHICLE_MODAL_LIST
      )('result_ctrl_info')
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.VEHICLE_MODAL_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    oneSelected() {
      return this.selectedItem.length === 1
    },

    /**
     * 複数選択しているか.
     * @return {Boolean}
     */
    multiSelected() {
      return this.selectedItem.length >= 1
    },

    /**
     * 部署参照モーダルID作成
     */
    makeSubModalId() {
      //開かれたdisplayModalsによりサブモーダルIDも変わるように修正
      let newId = 'department-modal'
      if (this.displayModals == 'two') {
        newId = 'department-modal-edit'
      } else if (this.displayModals == 'three') {
        newId = 'department-modal-three'
      } else if (this.displayModals == 'four') {
        newId = 'department-modal-four'
      }
      return newId
    },
  },

  methods: {
    getListOptions,
    search,
    clear,
    openSubModal,

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        vehicleCode: null,
        vehicleName: null,
        carrierCode: null,
        carrierName: null,
        numberplate: null,
        vehicleModel: null,
        vehicleCategory: null,
        deleted: DELETED_KIND.NOT_DELETED,
        size: getSearchMaxCount(),
        screenId: this.SCREEN_ID.VEHICLE_MODAL_LIST,
        domainName: 'result_fields',
      }
    },

    select() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('vehicle-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('vehicle-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('vehicle-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('vehicle-modal-four')
      }

      if (this.selectMode == 'single') {
        this.$emit('after-close-set', this.selectedItem[0])
      } else {
        this.$emit('after-close-set', this.selectedItem)
      }
    },

    unselect() {
      if (this.displayModals == 'one') {
        this.$bvModal.hide('vehicle-modal')
      } else if (this.displayModals == 'two') {
        this.$bvModal.hide('vehicle-modal-edit')
      } else if (this.displayModals == 'three') {
        this.$bvModal.hide('vehicle-modal-three')
      } else if (this.displayModals == 'four') {
        this.$bvModal.hide('vehicle-modal-four')
      }
      this.$emit('after-close-unset')
    },

    updateSize: function (size) {
      this.searchCondition.size = size
    },
  },
}
</script>

<style scoped></style>
